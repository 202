import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import classnames from "classnames";
import navigationConfig from "../../../../../configs/navigationConfig";
import SideMenuGroup from "./SideMenuGroup";
import { Badge } from "reactstrap";
import { ChevronRight } from "react-feather";
import { FormattedMessage } from "react-intl";
import { history } from "../../../../../history";
import { connect } from "react-redux";
import * as Icon from "react-feather"

const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

const SideMenuContent = (props) => {
  const [activeGroups, setActiveGroups] = useState([]);
  const [currentActiveGroup, setCurrentActiveGroup] = useState([]);
  const [tempArr, setTempArr] = useState([]);
  const [collapsedPath, setCollapsedPath] = useState(null);
  
  const parentArr = [];
  let filtered = deepCopy(navigationConfig)

  const redirectUnauthorized = () => {
    history.push("/misc/not-authorized");
  };

  const getIconComponent = (iconName) => {
    return Icon[iconName] || null;
  };

  const handleGroupClick = (id, parent = null, type = "") => {
    let open_group = activeGroups;
    let active_group = currentActiveGroup;
    let temp_arr = tempArr;

    if (type === "item" && parent === null) {
      active_group = [];
      temp_arr = [];
    } else if (type === "item" && parent !== null) {
      active_group = [];
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
      } else {
        temp_arr = [];
        temp_arr.push(parent);
      }
      active_group = temp_arr.slice(0);
    } else if (type === "collapse" && parent === null) {
      temp_arr = [];
      temp_arr.push(id);
    } else if (type === "collapse" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0);
      }
      if (temp_arr.includes(id)) {
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
      } else {
        temp_arr.push(id);
      }
    } else {
      temp_arr = [];
    }

    if (type === "collapse") {
      if (!open_group.includes(id)) {
        let temp = open_group.filter((obj) => active_group.indexOf(obj) === -1);
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter((obj) => !temp.includes(obj));
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0);
        }
        if (!open_group.includes(id)) {
          open_group.push(id);
        }
      } else {
        open_group.splice(open_group.indexOf(id), 1);
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0);
    }

    setActiveGroups(open_group);
    setTempArr(temp_arr);
    setCurrentActiveGroup(active_group);
  };

  const initRender = (parentArr) => {
    setActiveGroups(parentArr.slice(0));
    setCurrentActiveGroup(parentArr.slice(0));
  };

  useEffect(() => {
    initRender(parentArr[0] ? parentArr[0] : []);
  }, []);

  useEffect(() => {
    if (collapsedPath !== null) {
      props.collapsedMenuPaths(collapsedPath);
    }
    initRender(parentArr[0] ? parentArr[parentArr.length - 1] : []);
  }, [props.activePath]);


  if (!props.restaurant?.evaluation) {
    filtered = filtered?.filter(
      (item) => item.title !== "Evaluation" && item.groupTitle !== "Evaluations"
    );
  }

  if (!props.restaurant?.referral) {
    filtered = filtered?.filter(
      (item) => item.title !== "Referral System" && item.groupTitle !== "Referral"
    );
  }

  if (
    !props.restaurant?.referral &&
    !props.restaurant?.evaluation &&
    !props.restaurant?.spin_wheel &&
    !props.restaurant?.physical_spin_wheel &&
    !props.restaurant?.loyalty &&
    !props.restaurant?.punch_card &&
    (!props.restaurant?.menu  || !props.restaurant?.is_allow_ordering)
  ) {
    filtered = filtered?.filter((item) => item.title !== "Prizes");
  }

  if (!props.restaurant?.spin_wheel) {
    const index = filtered?.findIndex((elm) => elm.title === "Lottery Wheel");
    filtered[index].children = filtered[index]?.children?.filter(
      (item) => item.title !== "Digital Wheel"
    );
  }

  if (!props.restaurant?.physical_spin_wheel) {
    const index = filtered?.findIndex((elm) => elm.title === "Lottery Wheel");
    filtered[index].children = filtered[index]?.children?.filter(
      (item) => item.title !== "Physical Wheel"
    );
  }

  if (!props.restaurant?.spin_wheel && !props.restaurant?.physical_spin_wheel) {
    filtered = filtered?.filter(
      (item) => item.title !== "Lottery Wheel" && item.groupTitle !== "Lottery Wheel"
    );
  }

  if (!props.restaurant?.punch_card) {
    const index = filtered?.findIndex((elm) => elm.title === "Loyalty");
    filtered[index].children = filtered[index]?.children?.filter(
      (item) => item.title !== "Punch Cards"
    );
  }

  if (!props.restaurant?.loyalty) {
    const index = filtered?.findIndex((elm) => elm.title === "Loyalty");
    filtered[index].children = filtered[index]?.children?.filter(
      (item) => item.title !== "Tiers" && item.title !== "Rules" && item.title !== "Points Shop"
    );
  }

  if (!props.restaurant?.loyalty && !props.restaurant?.punch_card) {
    filtered = filtered?.filter(
      (item) => item.title !== "Loyalty" && item.groupTitle !== "LOYALTY"
    );
  }

  if (!props.restaurant?.is_allow_ordering) {
    const index = filtered?.findIndex((elm) => elm.title === "Menus");
    filtered[index].children = filtered[index]?.children?.filter(
      (item) => item.title !== "Orders" && item.title !== "Tables"
    );
  }

  if (!props.restaurant?.menu) {
    filtered = filtered?.filter(
      (item) => item.title !== "Menus" && item.groupTitle !== "Menus"
    );
  } else {
    const index = filtered?.findIndex((elm) => elm.title === "Menus");
    filtered[index].children = filtered[index]?.children?.filter((item) => {
      if (
        (item.permissions && item.permissions.includes(props.currentUser)) ||
        (props.currentUser !== "3" && item.permissions === undefined)
      )
        return item;
    });
  }

  const menuItems = filtered?.map((item) => {
    const IconComponent = getIconComponent(item.icon);
    const CustomAnchorTag = item.type === "external-link" ? `a` : Link;

    if (item.type === "groupHeader" && props.currentUser !== "3") {
      return (
        <li className="navigation-header" key={`group-header-${item.groupTitle}`}>
          <span>{<FormattedMessage id={item.groupTitle} />}</span>
        </li>
      );
    }

    let renderItem = (
      <li
        className={classnames("nav-item", {
          "has-sub": item.type === "collapse",
          open:
            activeGroups.includes(item.id) ||
            (props.isSubItemsOpen && item.type === "collapse"),
          "sidebar-group-active": currentActiveGroup.includes(item.id),
          hover: props.hoverIndex === item.id,
          active:
            (props.activeItemState === item.navLink && item.type === "item") ||
            (item.parentOf && item.parentOf.includes(props.activeItemState)),
          disabled: item.disabled,
        })}
        key={item.id}
        onClick={(e) => {
          e.stopPropagation();
          if (item.type === "item") {
            if (item.id === "logout") {
              e.preventDefault();
              localStorage.removeItem("token");
            }
            props.handleActiveItem(item.navLink);
            handleGroupClick(item.id, null, item.type);
            if (props.deviceWidth <= 1200 && item.type === "item") {
              props.toggleMenu();
            }
          } else {
            handleGroupClick(item.id, null, item.type);
          }
        }}
      >
        <CustomAnchorTag
          to={
            item.filterBase
              ? item.filterBase
              : item.navLink && item.type === "item"
              ? item.navLink
              : ""
          }
          href={item.type === "external-link" ? item.navLink : ""}
          className={`d-flex ${
            item.badgeText ? "justify-content-between" : "justify-content-start"
          }`}
          onMouseEnter={() => {
            props.handleSidebarMouseEnter(item.id);
          }}
          onMouseLeave={() => {
            props.handleSidebarMouseEnter(item.id);
          }}
          key={item.id}
          onClick={(e) => (item.type === "collapse" ? e.preventDefault() : "")}
          target={item.newTab ? "_blank" : undefined}
        >
          <div className="menu-text">
            {IconComponent && <IconComponent size={20} />}
            <span className="menu-item menu-title">
              <FormattedMessage id={item.title} />
            </span>
          </div>

          {item.badge ? (
            <div className="menu-badge">
              <Badge color={item.badge} className="mr-1" pill>
                {item.badgeText}
              </Badge>
            </div>
          ) : (
            ""
          )}
          {item.type === "collapse" ? (
            <ChevronRight className="menu-toggle-icon" size={13} />
          ) : (
            ""
          )}
        </CustomAnchorTag>

        {item.children ? (
          <SideMenuGroup
            group={item}
            handleGroupClick={handleGroupClick}
            activeGroup={activeGroups}
            handleActiveItem={props.handleActiveItem}
            activeItemState={props.activeItemState}
            handleSidebarMouseEnter={props.handleSidebarMouseEnter}
            activePath={props.activePath}
            hoverIndex={props.hoverIndex}
            initRender={initRender}
            parentArr={parentArr}
            triggerActive={undefined}
            currentActiveGroup={currentActiveGroup}
            permission={props.permission}
            currentUser={props.currentUser}
            redirectUnauthorized={redirectUnauthorized}
            collapsedMenuPaths={props.collapsedMenuPaths}
            toggleMenu={props.toggleMenu}
            deviceWidth={props.deviceWidth}
          />
        ) : (
          ""
        )}
      </li>
    );

    if (item.navLink && item.collapsed !== undefined && item.collapsed === true) {
      setCollapsedPath(item.navLink);
      props.collapsedMenuPaths(item.navLink);
    }

    if (
      item.type === "external-link" ||
      ((item.type === "item" || (item.type === "collapse" && item.children?.length > 0)) &&
        item.permissions &&
        item.permissions.includes(props.currentUser)) ||
      (props.currentUser !== "3" && item.permissions === undefined)
    ) {
      return renderItem;
    } else if (
      item.type === "item" &&
      item.navLink === props.activePath &&
      !item.permissions.includes(props.currentUser)
    ) {
      if (props.restaurant?.referral || props.restaurant?.evaluation)
        return <Redirect to={"/reward-lookup"} />;
      if (props.restaurant?.menu || props.restaurant?.is_allow_ordering)
        return <Redirect to={"/orders"} />;
      return <Redirect to={"/raise-support"} />;
    }
  });

  return <React.Fragment>{menuItems}</React.Fragment>;
};

const mapStateToProps = (state) => {
  return {
    restaurant: state.branches.restaurant.subscription,
  };
};

export default connect(mapStateToProps)(SideMenuContent);

