import React, { Children } from "react"
import * as Icon from "react-feather"
import wheelSVG from '../assets/img/svg/lottery-wheel.svg'
import physicalWheelSVG from '../assets/img/svg/physical-lottery-wheel.svg'

const navigationConfig = [
  {
    id: "Dashboard",
    title: "Dashboard",
    type: "item",
    permissions: ["1", "2"],
    icon: 'Home',
    navLink: "/",
  },
  {
    id: 'report',
    title: 'Reports',
    type: "collapse",
    icon: 'FileText',    
    children: [
      {
        id: "reports",
        title: "Reports",
        type: "item",
        icon: 'List',
        navLink: "/reports",
        permissions: ["1", "2"],
      },
      {
        id: "kpi",
        title: "KPI",
        type: 'item',
        icon: 'CheckSquare',
        navLink: "/kpi",
        permissions: ["1", "2"],
      }
    ]
  },
  {
    id: "menu",
    title: "Menus",
    type: "collapse",
    icon: 'Map',
    children: [
      {
        id: "menus",
        title: "Menus List",
        type: "item",
        icon: 'List',
        navLink: "/menus",
        permissions: ["1", "2"],
      },
      {
        id: 'orders',
        title: 'Orders',
        type: 'item',
        icon: 'ShoppingCart',
        navLink: "/orders",
        permissions: ["1", "2", "3"],
      },

      {
        id: 'tables',
        title: 'Tables',
        type: 'item',
        icon: 'Grid',
        navLink: "/tables",
        permissions: ["1", "2"],
      },
      {
        id: 'modifiers',
        title: 'Modifiers',
        type: 'item',
        icon: 'Layers',
        navLink: "/modifiers",
        permissions: ["1", "2"],
      },
    ],
    permissions: ["1", "2", "3"],
  },
  {
    id: "evaluation",
    title: "Evaluation",
    type: "collapse",
    icon: 'Edit3',
    children: [
      {
        id: 'reviewsForm',
        title: 'Evaluation Forms',
        type: 'item',
        icon: 'List',
        navLink: "/reviews-form",
        permissions: ["1", "2"],
      },
      {
        id: 'reviewsResults',
        title: 'Evaluation Results',
        type: 'item',
        icon: 'BarChart2',
        navLink: "/reviews-results",
        permissions: ["1", "2"],
      },
    ],
    permissions: ["1", "2"],
  },
  {
    id: "lottery",
    title: "Lottery Wheel",
    type: "collapse",
    icon: 'Target',
    children: [
      {
        id: "lotteryWheel",
        title: "Digital Wheel",
        type: "item",
        icon: <img src={wheelSVG} style={{ width: 25}} className="mr-1"/> ,
        navLink: "/lottery-wheels",
        permissions: ["1", "2"],
      },     
      {
        id: "physicalLotteryWheel",
        title: "Physical Wheel",
        type: "item",
        icon: <img src={physicalWheelSVG} style={{ width: 30}} className="mr-1"/>,
        navLink: "/physical-lottery-wheels",
        permissions: ["1", "2"],
      },
      {
        id: "lotteryWheelResults",
        title: "Lottery Results",
        type: "item",
        icon: 'BarChart2',
        navLink: "/lottery-results",
        permissions: ["1", "2"],
      }
    ],
    permissions: ["1", "2"],
  },
  {
    id: "loyalty",
    title: "Loyalty",
    type: "collapse",
    icon: 'Award',
    children: [
      {
        id: "tiers",
        title: "Tiers",
        type: "item",
        icon: 'Layers',
        navLink: "/loyalty/tiers",
        permissions: ["1", "2"],
      },
      {
        id: "rules",
        title: "Rules",
        type: "item",
        icon: 'AlertTriangle',
        navLink: "/loyalty/rules",
        permissions: ["1", "2"],
      },
      {
        id: "pointsShop",
        title: "Points Shop",
        type: "item",
        icon: 'ShoppingCart',
        navLink: "/loyalty/points-shops",
        permissions: ["1", "2"],
      },
      {
        id: "punchCard",
        title: "Punch Cards",
        type: "item",
        icon: "CreditCard",
        navLink: "/loyalty/punch-cards",
        permissions: ["1", "2"],
      }
    ],
    permissions: ["1", "2"]
  },
  {
    id: 'referralSystem',
    title: 'Referral System',
    type: 'item',
    icon: "UserPlus",
    navLink: "/referral",
    permissions: ["1", "2"],
  },
  {
    id: 'crm',
    title: 'CRM',
    type: 'collapse',  
    icon: "Share2",
    children: [
      {
        id: "campaign",
        title: "Marketing Campaigns",
        type: 'item',
        icon: "Send",
        navLink: "/campaign/list",
        permissions: ["1", "2"],
      },
      {
        id: "customer",
        title: "Customers",
        type: 'item',
        permissions: ["1", "2"],
        icon: "Users",
        navLink: "/customer/list",
      }
    ]
  },  
  {
    id: "prizes",
    title: "Prizes",
    type: "collapse",
    icon: "Gift",
    children: [
      {
        id: "prizes list",
        title: "Prizes List",
        type: "item",
        icon: "List",
        navLink: "/prizes",
        permissions: ["1", "2"],
      },
      {
        id: "rewardLookUp",
        title: "Prize Look Up",
        type: "item",
        icon: "Search",
        navLink: "/reward-lookup",
        permissions: ["1", "2", "3"],
      },
    ],
    permissions: ["1", "2", "3"]
  },
  {
    id: "discounts",
    title: "Discounts",
    type: "item",
    permissions: ["1"],
    icon: "Percent",
    navLink: "/discounts/list",
  },
  {
    id: "visualAds",
    title: "Visual Ads",
    type: "collapse",
    icon: "Tv",
    children: [
      {
        id: 'Sliders',
        title: 'Sliders',
        type: 'item',
        icon: "Image",
        navLink: "/sliders",
      },
      {
        id: "Pop Ups",
        title: "Pop Ups",
        type: "item",
        icon: "Film",
        navLink: "/pop-ups",
      },
    ],
    permissions: ["1", "2"]
  },    
  {
    id: "settings",
    title: "Settings",
    type: "item",
    permissions: ["1", "2"],
    icon: "Settings",
    navLink: "/settings",
  },  
]

export default navigationConfig
